import React, { FC } from 'react';

import Image from '~/components/image/Image';
import { IRelatedArticle } from '~/interfaces/components/RelatedArticles';

const Article: FC<IRelatedArticle> = ({ imageUrl, imageAlt, url, title }) => {
  return (
    <div className="article__wrapper" data-analytics-id="related-article">
      <a href={url}>
        <Image src={imageUrl ?? ''} alt={imageAlt ?? ''} />
      </a>
      <header className="article__header">
        <a href={url} className="article__title">
          {title}
        </a>
      </header>
    </div>
  );
};

export default Article;
