import React, { FC } from 'react';

import { IRelatedArticles } from '~/interfaces/components/RelatedArticles';

import Article from './Article/Article';

const RelatedArticles: FC<IRelatedArticles> = ({ relatedArticles, headerTitle }) => {
  const articles = relatedArticles?.map((article, i) => {
    return (
      <Article
        imageUrl={article.imageUrl}
        title={article.title}
        url={article.url}
        key={`${article.publishDate}`}
        index={i}
      />
    );
  });

  return (
    <div className="related-articles">
      <div className="related-articles__title">
        <h3>{headerTitle}</h3>
      </div>
      {articles}
    </div>
  );
};

export default RelatedArticles;
